import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loader } from "../../../Assets";
import { Box, IconButton, LinearProgress } from "@mui/material";
import useValidator from "../../../utils/CustomValidator";
import { MdPhotoCamera } from "react-icons/md";
import { db, storage } from "../../../firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
//import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import PhoneInput from "react-phone-input-2";
import "./createPost.css";
import { useTranslation } from 'react-i18next';
import { GrFormClose } from 'react-icons/gr';

import {
  useSelector,
  //useDispatch
} from "react-redux";

///import { createPostAction } from "../../../redux copy/actions";

const CreatePost = ({ locationAndCoords }) => {
  const { t } = useTranslation();
  //const dispatch = useDispatch()
  //const { isLoading } = useSelector((state) => state.posts)
  const { user } = useSelector((state) => state?.user)
  const [images, setImages] = useState([]);
  // const [video, setVideo] = useState("");
  // const [videoLink, setVideoLink] = useState("");
  const [urls, setUrls] = useState([]);
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [validator, showValidationMessage] = useValidator();
  const [progress, setProgress] = useState(0);
  const [previewImages, setPreviewImages] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [error, setError] = useState("");
  const [message, setMessage] = useState(null);
  const [postData, setPostData] = useState({
    type: "",
    city: "",
    neighborhood: "",
    streetNumber: "",
    postalCode: "",
    price: "",
    category: "",
    currency: "",
    bedrooms: "",
    numberOfToilets: "",
    numberAvailable: "",
    dining: "",
    parking: "",
    fenced: ""
  });
  const {
    type,
    city,
    neighborhood,
    streetNumber,
    postalCode,
    price,
    category,
    currency,
    numberOfToilets,
    numberAvailable,
    bedrooms,
    dining,
    parking,
    fenced
  } = postData;
  const [countryCode, country, citiesData] = locationAndCoords || [];
  console.log(locationAndCoords)

  useEffect(() => {
    const result = document.getElementById('phone__input').getElementsByClassName('special-label');
    result[0].style.display = 'none';
  }, []);


  const handlePhoneInputChange = (phone) => {
    setPhoneNumber(phone);
  };
  const removeImage = (index) => {
    const newPreviews = previewImages.filter((_, i) => i !== index);
    setPreviewImages(newPreviews);
  };
  const handleChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      setPreviewImages((prev) => [
        ...prev,
        URL.createObjectURL(e.target.files[i]),
      ]);
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();
      setImages((prevState) => [...prevState, newImage]);
    }
  };
  // const handleVideoInputChange = (e) => {
  //   let videoObj = e.currentTarget.files[0];
  //    console.log(videoObj)
  //   let {
  //     name,
  //     size,
  //     type
  //   } = videoObj;
  //   type = type.split("/")[0];

  //   size = size / 1000000
  //   //for not uploading the file more than 10 MB
  //   if (size > 10) {
  //     alert("please upload file less than 10 MB");
  //     return;
  //   }else
  //   if (type !== "video") {
  //     alert("please upload video only");
  //     return;
  //   }else{
  //     setVideo(e.target.files[0])
  //     const videoStorageRef = ref(storage, `/postVideos/${name}`);
  //     const uploadTask = uploadBytesResumable(videoStorageRef,video);
  //     console.log()

  //       // uploadTask.on(
  //       //   "state_changed",
  //       //   (snapshot) => {
  //       //     const percent = Math.round(
  //       //       (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //       //     );
  //       //     setProgress(percent);
  //       //   },
  //       //   (err) => {
  //       //     const errorMessage = err.message;
  //       //     setError(errorMessage);
  //       //   },
  //       //   async () => {
  //       //     await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
  //       //       setVideoLink(url);
  //       //     });
  //       //   }
  //       // );

  //   }

  // };


  const handleCreatePost = async (e) => {
    e.preventDefault();
    setLoading(true);
    const promises = [];
    images.map((image) => {
      if (!image) {
        setError(t("please upload photos"));
      } else {
        setError("");
        const storageRef = ref(storage, `/postImages/${image.name}`);

        const uploadTask = uploadBytesResumable(storageRef, image);
        promises.push(uploadTask);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(percent);
          },
          (err) => {
            const errorMessage = err.message;
            setError(errorMessage);
          },
          async () => {
            await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              setUrls((prevState) => [...prevState, url]);
            });
          });
      }
      return null
    });

    await Promise.all(promises)
      .then(() => {
        setMessage("upload success");
      })

    if (message === "upload success" && validator.allValid()) {
      // dispatch(createPostAction(postData,id,phoneNumber,urls,navigate))
      addDoc(collection(db, "apartments"), {
        timestamp: serverTimestamp(),
        imageUrls: urls,
        postedBy: user?.fullName,
        userId: user?.id,
        type: type,
        city: city,
        phoneNumber: phoneNumber,
        country: country,
        neighborhood: neighborhood,
        streetNumber: streetNumber,
        countryCode: countryCode,
        postalCode: postalCode,
        price: price,
        fenced: fenced,
        category: category,
        currency: currency,
        bedrooms: bedrooms,
        numberOfToilets: numberOfToilets,
        numberAvailable: numberAvailable,
        dining: dining,
        parking: parking,
        likes: 0,
        // videoUrl:videoLink
        // userProfileUrl: user?.photoURL,
      })
      setProgress(0);
      setPreviewImages(null);
      setLoading(false);
      navigate("/");

    } else {
      showValidationMessage(true);
      setLoading(false);
    }




  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setPostData({ ...postData, [e.target.name]: value });
  };



  return (
    <div className="createPost__container">
      <form onSubmit={handleCreatePost} className="createPost__form">
        {previewImages?.map(
          (image, index) =>
            image && (
              <Box sx={{ width: "100%", margin: "10px 0px" }}>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  key={index}
                />
              </Box>
            )
        )}
        <div className="image__uploadContainer createPost__input">
          <h4>{t("upload photos")}</h4>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              autocomplete="on"
              hidden
              // accept="image/*"
              accept=".jpg, .jpeg, .png"
              type="file"
              multiple
              onChange={handleChange}
            />
            <MdPhotoCamera size={20} color="" />
          </IconButton>
        </div>
        <div className="preview__container">
          {previewImages?.map(
            (preview, index) =>
              preview && (
                <div className="my-2">
                  <div className="preview__imageContainer">
                    <img
                      key={index}
                      className="previewImage"
                      src={preview}
                      alt=""

                    />
                    <GrFormClose color="#4db5ff"
                      size={20}
                      className="close__prevIcon"
                      onClick={() => removeImage(index)} />
                  </div>
                </div>
              )
          )}
        </div>
        <div className="createPost__input">
          <select
            className="createPost__select"
            labelid="type-select"
            id="typeSelect"
            label={t("select type")}
            name="type"
            onChange={handleInputChange}
            value={postData.type}
          >
            <option >{t("select type")}</option>
            <option value={t("modern studio")}>{t("modern studio")}</option>
            <option value="bungalow">{t("bungalow")}</option>
            <option value={t("bungalow")}>{t("modern room")}</option>
            <option value={t("simple apartment")}>{t("simple apartment")}</option>
            <option value={t("guest house")}>{t("guest house")}</option>
            <option value={t("apartment flat")}>{t("apartment flat")}</option>
          </select>
          <label className="error text-danger">
            {validator.message(
              "quality",
              postData.type,
              "required|type",
              {
                messages: {
                  required: t("required"),
                },
              }
            )}
          </label>
        </div>
        <div className="createPost__input">
          <input
            autocomplete="on"
            type="text"
            placeholder={t("price")}
            name="price"
            value={postData.price}
            onChange={handleInputChange}
            className=""
          />
          <label className="register__error text-danger">
            {validator.message("price", postData.price, "required|price", {
              messages: {
                required: t("required"),
              },
            })}
          </label>
        </div>
        <div className="createPost__input">
          <select
            className="register__select"
            labelid="currency-select"
            id="currencySelect"
            label={t("select currency")}
            name="currency"
            onChange={handleInputChange}
            value={postData.currency}
          >
            <option>{t("select currency")}</option>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
            <option value="FCFA">FCFA</option>
            <option value="NAIRA">NAIRA</option>
          </select>
          {/* <label className="error text-danger">
                      {validator.message(
                        "currency",
                        postData.currency,
                        "required|currency",
                        {
                          messages: {
                            required: t("required"),
                          },
                        }
                      )}
                    </label> */}
        </div>
        <div className="createPost__input">
          <select
            className="register__select"
            labelid="quality-select"
            id="qualitySelect"
            label={t("select category")}
            name="category"
            onChange={handleInputChange}
            value={postData.category}
          >
            <option>{t("select category")}</option>
            <option value="for rent">{t("for rent")}</option>
            <option value="for sale">{t("for sale")}</option>
          </select>

          <label className="register__error text-danger">
            {validator.message("category", postData.category, "required|category", {
              messages: {
                required: t("required"),
              },
            })}
          </label>

        </div>
        <div className="createPost__input">
          <select
            className="register__select"
            labelid="city-select"
            id="citySelect"
            label="Select City"
            name="city"
            onChange={handleInputChange}
            value={postData.city}
          >
            {
              !citiesData ? (
                <option value="for rent">{t("no cities")}</option>
              ) : (
              <option value="for rent">{t("Select City")}</option>
              )
            }
           
            {
              citiesData?.map((option, index) => {
                return <option key={index} value={option} style={{ color: "black;" }}>{option}</option>
              })
            }
          </select>

          <label className="register__error text-danger">
            {validator.message("city", postData.city, "required|city", {
              messages: {
                required: t("required"),
              },
            })}
          </label>
        </div>


        <div className="createPost__input">
          <input
            autocomplete="on"
            type="text"
            placeholder={t("quarter")}
            name="neighborhood"
            value={postData.neighborhood}
            onChange={handleInputChange}
            className=""
          />
          <label className="register__error text-danger">
            {validator.message(
              "neighborhood",
              postData.neighborhood,
              "required|neighborhood",
              {
                messages: {
                  required: t("required"),
                },
              }
            )}
          </label>
        </div>
        <div className="createPost__input">
          <input
            autocomplete="on"
            type="text"
            placeholder={t("street number")}
            name="streetNumber"
            value={postData.streetNumber}
            onChange={handleInputChange}
            className=""
          />
        </div>
        <div className="createPost__input">
          <input
            autocomplete="on"
            type="number"
            placeholder={t("postal code")}
            name="postalCode"
            value={postData.postalCode}
            onChange={handleInputChange}
            className=""
          />
          <label className="register__error text-danger">
            {validator.message(
              "postalCode",
              postData.postalCode,
              "required|postalCode",
              {
                messages: {
                  required: t("required"),
                },
              }
            )}
          </label>
        </div>

        {
          postData.type !== "modern room" && (
            <>
              <div className="createPost__input">
                <input
                  autocomplete="on"
                  type="text"
                  placeholder={t("no of bedrooms")}
                  name="bedrooms"
                  value={postData.bedrooms}
                  onChange={handleInputChange}
                  className=""
                />
                <label className="register__error text-danger">
                  {validator.message(
                    "bedrooms",
                    postData.bedrooms,
                    "required|bedrooms",
                    {
                      messages: {
                        required: t("required"),
                      },
                    }
                  )}
                </label>
              </div>
              <div className="createPost__input">
                <input
                  autocomplete="on"
                  type="text"
                  placeholder={t("no of toilets")}
                  name="numberOfToilets"
                  value={postData.numberOfToilets}
                  onChange={handleInputChange}
                  className=""
                />
                <label className="register__error text-danger">
                  {validator.message(
                    "numberOfToilets",
                    postData.numberOfToilets,
                    "required|numberOfToilets",
                    {
                      messages: {
                        required: t("required"),
                      },
                    }
                  )}
                </label>
              </div>
              <div className="createPost__input">
                <select
                  className="register__select"
                  labelid="dining-select"
                  id="diningSelect"
                  label={t("dining")}
                  name="dining"
                  onChange={handleInputChange}
                  value={postData.dining}
                >
                  <option>{t("dining")}</option>
                  <option value={t("dining available")}>{t("dining available")}</option>
                  <option value={t("no dining")}>{t("no dining")}</option>
                </select>
                <label className="error text-danger">
                  {validator.message(
                    "dining",
                    postData.dining,
                    "required|dining",
                    {
                      messages: {
                        required: t("required"),
                      },
                    }
                  )}
                </label>
              </div>
              <div className="createPost__input">
                <select
                  className="register__select"
                  labelid="parking-select"
                  id="parkingSelect"
                  label={t("parking")}
                  name="parking"
                  onChange={handleInputChange}
                  value={postData.parking}
                >
                  <option>{t("parking")}</option>
                  <option value={t("parking available")}>{t("parking available")}</option>
                  <option value={t("no parking")}>{t("no parking")}</option>
                </select>
                <label className="error text-danger">
                  {validator.message(
                    "parking",
                    postData.parking,
                    "required|parking",
                    {
                      messages: {
                        required: t("required"),
                      },
                    }
                  )}
                </label>
              </div>
            </>
          )
        }
        <div className="createPost__input">
          <select
            className="register__select"
            labelid="fenced-select"
            id="fencedSelect"
            label={t("fence")}
            name="fenced"
            onChange={handleInputChange}
            value={postData.fenced}
          >
            <option>{t("fence")}</option>
            <option value={t("fenced")}>{t("fenced")}</option>
            <option value={t("not fenced")}>{t("not fenced")}</option>
          </select>
          <label className="error text-danger">
            {validator.message(
              "parking",
              postData.fenced,
              "required|fenced",
              {
                messages: {
                  required: t("required"),
                },
              }
            )}
          </label>
        </div>
        <div className="createPost__input mb-1">
          <input
            autocomplete="on"
            type="text"
            placeholder={t("no available")}
            name="numberAvailable"
            value={postData.numberAvailable}
            onChange={handleInputChange}
            className=""
          />
          <label className="register__error text-danger">
            {validator.message(
              "numberAvailable",
              postData.numberAvailable,
              "required|numberAvailable",
              {
                messages: {
                  required: t("required"),
                },
              }
            )}
          </label>
        </div>
        <div className="createPost__input" id="phone__input">
          <PhoneInput
            type="text"
            country={"cmr"}
            enableAreaCodes={true}
            // onlyCountries={['us','de','ro']}
            // areaCodes={{us: ['999']}}
            inputProps={{
              name: "phoneNumber",
              country: "cm",
              required: true,
              autoFocus: true,
            }}
            // containerStyle={{
            //   Padding: "5px",
            // }}
            inputStyle={{
              width: "100%",
              height: "42px",
              borderRadius: "10px",
              outline: "none",
              margin: "10px 0",
              backgroundColor: "#fff",
              border: "none",

            }}
            regions={["north-america", "africa", "europe"]}
            value={phoneNumber}
            onChange={handlePhoneInputChange}
            // onChange={props.inputChange}
            // value={props.data.phonNumber}
            isValid={(value, country) => {
              if (value.match(/12345/)) {
                return "Invalid value: " + value + ", " + country.name;
              } else if (value.match(/1234/)) {
                return false;
              } else {
                return true;
              }
            }}
          />
          <label className="error text-danger">
            {validator.message(
              "phoneNumber",
              phoneNumber,
              "required|phoneNumber",
              {
                messages: {
                  required: t("required"),
                },
              }
            )}
          </label>
        </div>

        {/* <div className="image__uploadContainer createPost__input">
          <label>{t("upload video")}</label>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              autocomplete="on"
              hidden
              type="file"
              onChange={handleVideoInputChange}
            />
             <SmartDisplayIcon size={20} color="" /> 
          </IconButton>
        </div> */}
        <div className="imageUpload__error">
          <span className="text-danger">{error}</span>
        </div>
        {loading && (<img src={loader} alt="" className="loading__image" />)}
        <div className="submit__container">
          <button type="submit" className={message === "upload success" ? "register__submit2" : "register__submit"}>
            {message === "upload success" ? "FINISH" : t("create")}
          </button>
          {/* {
       message === "upload success" && (
          <button type="submit" className="register__submit2">
          finish
          </button>
        )
       } */}
        </div>
        {error && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default CreatePost;
