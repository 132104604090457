import React from 'react';
import './loader.css'
import { loader } from '../../Assets/index';
import { Link } from 'react-router-dom';

const Loader = () => {
  return (
    <div className='loader'>
       <img src={loader} alt="" /> 
       <Link to='/' className="loader__LogoLink">
        Buy Property...Rent Property
        </Link>
    </div>
  )
}

export default Loader