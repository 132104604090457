import * as action from "./constants";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  getDoc,
  doc,
  setDoc,
  addDoc,
  deleteDoc,
  serverTimestamp,
  updateDoc,
  //getDocs
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { db, auth, signInWithGooglePopup } from "../firebaseConfig";
import {
  getCurrentSaver,
  getCurrentlySavedData,
  //getCurrentSaverId,
  getSubscriptionExpiryDate,
  getSubscriptionExpiryDateId,
  // eslint-disable-next-line
  refreshUser,
  getSubscriptionExpiryData,
} from "../utils/utils";

export const createPostAction =
  (postData, userId, phoneNumber, urls, navigate) => (dispatch) => {
    dispatch({ type: action.START_LOADING });
    try {
      const {
        type,
        country,
        city,
        neighborhood,
        price,
        fenced,
        category,
        currency,
        numberOfToilets,
        numberAvailable,
        bedrooms,
        dining,
        parking,
      } = postData;

      const { displayName, photoURL } = auth.currentUser;
      const newPost = {
        timestamp: serverTimestamp(),
        imageUrls: urls,
        postedBy: displayName,
        userId: userId,
        type: type,
        city: city,
        fenced: fenced,
        phoneNumber: phoneNumber,
        country: country,
        neighborhood: neighborhood,
        price: price,
        category: category,
        currency: currency,
        bedrooms: bedrooms,
        numberOfToilets: numberOfToilets,
        numberAvailable: numberAvailable,
        dining: dining,
        parking: parking,
        userProfileUrl: photoURL,
      };
      addDoc(collection(db, `/apartments`), newPost);
      dispatch({ type: action.END_LOADING });
      navigate("/");
    } catch (error) {}
  };

// ===========================REMOVE FROM POSTS ======================================

export const deletePostAction = (id, postId) => async (dispatch) => {
  try {
    if (id === auth.currentUser.uid) {
      const postDocRef = doc(db, "apartments", postId);
      await deleteDoc(postDocRef);

      dispatch({ type: action.DELETE_POST, payload: postId });
    }
  } catch (error) {
    console.log(error);
  }
};
export const getAllPosts = () => async (dispatch) => {
  try {
    dispatch({ type: action.START_LOADING });
    const data = query(
      collection(db, "apartments"),
      orderBy("timestamp", "desc")
    );

    const getData = async () => {
      await onSnapshot(data, (querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          post: doc.data(),
        }));

        const forRent = newData.filter(({ post }) =>
          post?.category?.toLowerCase().match("for rent")
        );
        const forSale = newData.filter(({ post }) =>
          post?.category?.toLowerCase().match("for sale")
        );
        //console.log(forRent)

        dispatch({ type: action.GET_ALL_POSTS, payload: newData });
        dispatch({ type: action.GET_ALL_SALE_POSTS, payload: forSale });
        dispatch({ type: action.GET_ALL_RENTS_POSTS, payload: forRent });
      });
    };
    getData();
    dispatch({ type: action.END_LOADING });
  } catch (error) {
    console.log(error);
  }
};
export const getFavoritesAction = (userId) => async (dispatch) => {
  const data = query(
    collection(db, `/favorites/${userId}/clientFavorites`),
    orderBy("timestamp", "desc")
  );
  const currentFavorites = await getCurrentlySavedData(data);
  console.log(currentFavorites);

  dispatch({ type: action.GET_FAVORITES, payload: currentFavorites });
};

// ===========================SEARCH ACTIONS======================================

export const searchAction =
  (posts, searchTerm, today, expiryDate, navigate) => (dispatch) => {
    // console.log('search term actions',searchTerm)
    // console.log('actions posts',posts)

    try {
      dispatch({ type: action.START_LOADING_SEARCH });
      if (!expiryDate || today > expiryDate) {
        navigate("/subscribe");
      }

      const filtered = posts.filter(
        (post) =>
          // console.log('action post',post),
          post?.post?.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          post?.post?.neighborhood
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase())
        // for offline testing
        // post?.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        // post?.neighborhood
        //   ?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      dispatch({ type: action.SET_SEARCH_TERM, payload: filtered });
      dispatch({ type: action.END_LOADING });
    } catch (error) {}
  };

// ===========================REMOVE FROM FAVORITES======================================

export const removeFromFavoritesAction =
  (userId, postId, likes) => async (dispatch) => {
    try {
      console.log(userId, postId, likes);
      ///  const likedByDocRef = doc(db, `/favorites/${postId}/savedBy`, postId);
      const postDocRef = doc(
        db,
        `/favorites/${userId}/clientFavorites`,
        postId
      );
      //const taskDocRef = doc(db, "apartments", postId);
      //updateDoc(taskDocRef, auth.currentUser.uid, { likes: likes - 1 });
      //await deleteDoc(likedByDocRef);
      await deleteDoc(postDocRef);
      const data = query(collection(db, `/favorites/${postId}/savedBy`));
      const getData = async () => {
        await onSnapshot(data, (querySnapshot) => {
          const snapshot = querySnapshot.docs.map((doc) => doc.data());
          const newData = snapshot?.map(({ likedBy }) => likedBy);
          console.log(newData);
          dispatch({ type: action.GET_SAVED_BY, payload: newData });
        });
      };
      getData();
    } catch (error) {
      console.log(error);
    }
  };

// ===========================TOGGLE ADD TO FAVORITES======================================

export const toggleSaveFavoritesAction =
  (item, postId, userId) => async (dispatch) => {
    localStorage.clear()
    const {
      type,
      country,
      city,
      neighborhood,
      price,
      category,
      currency,
      numberOfToilets,
      numberAvailable,
      bedrooms,
      dining,
      parking,
      phoneNumber,
      fenced,
      imageUrls,
      timestamp,
      likes,
    } = item;

    const currentData = query(collection(db, `/favorites/${postId}/savedBy`));

    const currentlySavedBy = await getCurrentlySavedData(currentData);
    const currentSaver = getCurrentSaver(currentlySavedBy, userId);
    dispatch({ type: action.GET_SAVED_BY, payload: currentSaver })
    //  console.log(currentlySavedBy)
    // console.log(currentSaver)

    if (!currentSaver[0]) {
      console.log("not yet saved by me, saving now...");
      const newLikes = likes + 1;
     console.log(currentlySavedBy,userId, postId);
      const colRefUser = collection(db, `/favorites/${postId}/savedBy`);
      addDoc(colRefUser, {
        savedBy: userId,
      });
      const colRef = collection(db, `/favorites/${userId}/clientFavorites`);
      const taskDocRef = doc(db, "apartments", postId);
      updateDoc(taskDocRef, {
        likes: newLikes,
      });
      addDoc(colRef, {
        imageUrls: imageUrls,
        type: type,
        city: city,
        phoneNumber: phoneNumber,
        country: country,
        neighborhood: neighborhood,
        price: price,
        fenced: fenced,
        category: category,
        currency: currency,
        bedrooms: bedrooms,
        numberOfToilets: numberOfToilets,
        numberAvailable: numberAvailable,
        dining: dining,
        parking: parking,
        timestamp: timestamp,
        id: postId,
      });

      dispatch({ type: action.SAVED_TO_FAVORITES });
      const data = query(
        collection(db, `/favorites/${userId}/clientFavorites`),
        orderBy("timestamp", "desc")
      );
      const currentFavorites = await getCurrentlySavedData(data);
      dispatch({ type: action.GET_FAVORITES, payload: currentFavorites });
      // }

      //else if (currentSaver) {
      //    const newLikes = likes - 1
      //   console.log("I already saved this, removing now... now...");

      //   const currentSaverId = getCurrentSaverId(currentlySavedBy,userId);

      //   console.log(currentSaverId)

      //    const savedByDocRef = doc(db,`/favorites/${postId}/savedBy`);
      //   const savedItemsDocRef = doc(db,`/favorites/${userId}/clientFavorites`);
      //   const currentlySavedItems =   await getCurrentlySavedData(savedItemsDocRef)
      //   //dispatch({ type: action.GET_SAVED_BY_ID, payload: currentlySavedItems })
      //   console.log(currentlySavedItems)
      //    const savedItemId =  getSavedItemId(currentlySavedItems,postId)
      //     console.log(savedItemId)
      //       // const currentSaver = getCurrentSaver(currentlySavedFavorites,userId);
      //       // console.log(currentSaver)
      //     // const handleDelete = async () => {
      //       const taskDocRef = doc(db, "apartments", postId);
      //       updateDoc(taskDocRef, { likes:newLikes })
      //       .then(async() => {
      //         await deleteDoc(savedByDocRef, currentSaverId);

      //       })
      //       .then(async() => {
      //         await deleteDoc(savedItemsDocRef, savedItemId);
      //           dispatch({ type: action.REMOVE_SAVED_BY });

      //           const data = query(
      //             collection(db, `/favorites/${userId}/clientFavorites`),
      //             orderBy("timestamp", "desc")
      //           );
      //           const currentFavorites = await getCurrentlySavedData(data)
      //           dispatch({ type: action.GET_FAVORITES, payload: currentFavorites });

      //       })
    }
  };

// =======================GET FAVORITES  SAVED BY USER======================================

export const getUsersWhoSavedToFavoritesAction =
  (postId, userId) => async (dispatch) => {
    const data = query(collection(db, `/favorites/${postId}/savedBy`));

    const newData = await getCurrentlySavedData(data);
    //console.log(newData)
    
    

    const savedBy = getCurrentSaver(newData, userId,postId);
    
    //console.log(savedBy)
    // const itemId = getCurrentSaverId(newData,userId);
    //console.log(itemId)

    dispatch({ type: action.GET_SAVED_BY, payload: savedBy[1] });
    // dispatch({ type: action.GET_SAVED_BY_ID, payload: itemId })
  };

// ===========================AUTHENTICATION ACTIONS======================================

// ===========================REGISTER ACTIONS======================================

export const RegisterAction = (formData, navigate) => (dispatch) => {
  
    const {
      username,
      firstName,
      lastName,
      email,
      role,
      country,
      city,
      password,
      phoneNumber,
      isSubscribed,
      isTrialDone,
    } = formData;
    const colRef = collection(db, "users");
  //  const users =  getDocs(doc(colRef, auth.currentUser?.uid)).then((snapshot) => {
  //     //const name = snapshot.data().displayName;
     
  //   });
    try {
      dispatch({ type: action.START_AUTH_LOADING });
    createUserWithEmailAndPassword(auth, email, password).then(() => {
    
  
      setDoc(doc(colRef, auth.currentUser.uid), {
        joinedOn: serverTimestamp(),
        displayName: username,
        phoneNumber: phoneNumber,
        fullName: firstName + " " + lastName,
        country: country,
        city: city,
        role: role,
        email: email,
        isSubscribed: isSubscribed,
        isTrialDone: isTrialDone,
        id: auth.currentUser.uid,
      }).then(() => {
        sendEmailVerification(auth.currentUser).then(() => {
          auth.useDeviceLanguage();
        });
        auth.signOut();
        dispatch({ type: action.END_AUTH_LOADING });
        navigate("/verify-email");
      });
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: action.SET_LOGIN_MESSAGE,
      payload: error.code,
    });
    dispatch({ type: action.END_AUTH_LOADING });
  }
};
// ===========================SIGN IN ACTION======================================

export const signInAction = (formData, navigate) => (dispatch) => {
  try {
    dispatch({ type: action.START_AUTH_LOADING });
    const { email, password } = formData;
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        const colRef = collection(db, "users");
        getDoc(doc(colRef, auth.currentUser?.uid)).then((snapshot) => {
          //const name = snapshot.data().displayName;
          dispatch({ type: action.LOG_IN_USER, payload: snapshot.data() });
          dispatch({ type: action.END_AUTH_LOADING });
          navigate(`/`);
        });
        // ...
      })
      .catch((error) => {
        console.log(error.code);

        dispatch({
          type: action.SET_LOGIN_MESSAGE,
          payload: "please check your login credentials or network connection",
        });
        dispatch({ type: action.END_LOADING });
      });
  } catch (error) {
    console.log(error.code);
  }
};
export const googleAuthSignInAction = (navigate) => async (dispatch) => {

  try {


    dispatch({ type: action.START_AUTH_LOADING });
    const response = await signInWithGooglePopup();
     
        const { email, emailVerified } = response?.user
       if(email && emailVerified === true){
        
        const colRef = collection(db, "users");
          getDoc(doc(colRef, auth.currentUser?.uid)).then((snapshot) => {
            //const name = snapshot.data().displayName;
            dispatch({ type: action.LOG_IN_USER, payload: snapshot.data() });
            dispatch({ type: action.END_AUTH_LOADING });
            navigate(`/`);
           });
       }else {
        dispatch({
          type: action.SET_LOGIN_MESSAGE,
          payload: "please check your login credentials or network connection",
        });
        dispatch({ type: action.END_LOADING });
       }
        
  } catch (error) {
    dispatch({
      type: action.SET_LOGIN_MESSAGE,
      payload:error.code,
    });
  }
   
 
}

// ===========================SIGN OUT ACTION======================================
export const singOutAction = (navigate) => (dispatch) => {
  try {
    localStorage.clear();
    signOut(auth);
    dispatch({ type: action.LOG_OUT_USER, payload: "" });
    navigate("/");
  } catch (error) {}
};
// ===========================SUBSCRIPTION ACTION======================================
export const monthlySubscriptionAction =
  (isSubscribed, isTrialDone, subscriptionExpiryDateId,role,navigate) =>
  async (dispatch) => {
    const userId = auth.currentUser.uid;
    try {
      dispatch({ type: action.START_LOADING });
      const taskDocRef = doc(db, "users", userId);
      const today = new Date();
      const newSubscriptionExpiryDate = new Date(
       role === "agent" ? today.getTime() + 5 * 24 * 60 * 60 * 1000 : today.getTime() + 1 * 24 * 60 * 60 * 1000
      );

      if (isSubscribed === false && isTrialDone === false) {
        console.log("first time subscriber");
        console.log(isSubscribed);
        console.log(isTrialDone);
        updateDoc(taskDocRef, {
          isSubscribed: true,
          isTrialDone: true,
        }).then(async () => {
          const colRef = collection(
            db,
            `users/${userId}/subscriptionExpiryDate`
          );
          addDoc(colRef, {
            expiryDate: newSubscriptionExpiryDate,
          });
          const newData = await getSubscriptionExpiryData(userId);
          const expiryDate = getSubscriptionExpiryDate(newData);
          dispatch({
            type: action.GET_SUBSCRIPTION_EXPIRY_DATE,
            payload: expiryDate,
          });
          const user = await refreshUser();
          dispatch({ type: action.REFRESH_USER, payload: user });
          dispatch({ type: action.SUBSCRIBE_USER });
          dispatch({ type: action.END_LOADING });
          navigate(`/profile/${auth.currentUser.displayName}`);
        });
      } else if (isSubscribed === false && isTrialDone === true) {
        console.log("second time subscriber");
        const expiryTaskDocRef = doc(
          db,
          `users/${userId}/subscriptionExpiryDate`,
          subscriptionExpiryDateId
        );
        updateDoc(taskDocRef, {
          isSubscribed: true,
        }).then(async () => {
          updateDoc(expiryTaskDocRef, {
            expiryDate: newSubscriptionExpiryDate,
          });
          const newData = await getSubscriptionExpiryData(userId);
          const expiryDate = getSubscriptionExpiryDate(newData);
          dispatch({
            type: action.GET_SUBSCRIPTION_EXPIRY_DATE,
            payload: expiryDate,
          });
          const user = await refreshUser();
          dispatch({ type: action.REFRESH_USER, payload: user });
          dispatch({
            type: action.GET_SUBSCRIPTION_EXPIRY_DATE,
            payload: expiryDate,
          });
          dispatch({ type: action.SUBSCRIBE_USER });
          dispatch({ type: action.END_LOADING });
          navigate(`/profile/${auth.currentUser.displayName}`);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
export const unSubscriptionAction =
  (userId, isSubscribed, navigate) => async (dispatch) => {
    const today = new Date();
    // const newSubscriptionExpiryDate = new Date(
    //   today.getTime() + 1 * 24 * 60 * 60 * 1000
    // );

    const newData = await getSubscriptionExpiryData(userId);
    const expiryDate = getSubscriptionExpiryDate(newData);
    dispatch({
      type: action.GET_SUBSCRIPTION_EXPIRY_DATE,
      payload: expiryDate,
    });
    const expiryDateId = getSubscriptionExpiryDateId(newData);

    //console.log(expiryDate, expiryDateId)
    dispatch({
      type: action.GET_SUBSCRIPTION_EXPIRY_DATE_ID,
      payload: expiryDateId,
    });
    // const expiryTaskDocRef = doc(
    //   db,
    //   `users/${userId}/subscriptionExpiryDate`,
    // expiryDateId
    // );

    const taskDocRef = doc(db, "users", userId);

    if (isSubscribed === true && today >= expiryDate) {
      updateDoc(taskDocRef, {
        isSubscribed: false,
      });
      // .then(async() => {
      //   updateDoc(expiryTaskDocRef, {
      //     expiryDate:newSubscriptionExpiryDate,
      //   })
      // })
      const newData = await getSubscriptionExpiryData(userId);
      const expiryDate = getSubscriptionExpiryDate(newData);
      dispatch({
        type: action.GET_SUBSCRIPTION_EXPIRY_DATE,
        payload: expiryDate,
      });
      const expiryDateId = getSubscriptionExpiryDateId(newData);
      dispatch({
        type: action.GET_SUBSCRIPTION_EXPIRY_DATE,
        payload: expiryDate,
      });
      const user = await refreshUser();
      dispatch({ type: action.REFRESH_USER, payload: user });
      // console.log(expiryDate, expiryDateId)
      dispatch({
        type: action.GET_SUBSCRIPTION_EXPIRY_DATE_ID,
        payload: expiryDateId,
      });
      navigate("/");
    }
  };

export const getSubscriptionExpiryDataAction = (userId) => async (dispatch) => {
  const newData = await getSubscriptionExpiryData(userId);
  const expiryDate = getSubscriptionExpiryDate(newData);
  const expiryDateId = getSubscriptionExpiryDateId(newData);

  dispatch({ type: action.GET_SUBSCRIPTION_EXPIRY_DATE, payload: expiryDate });
  // console.log(user)
  dispatch({
    type: action.GET_SUBSCRIPTION_EXPIRY_DATE_ID,
    payload: expiryDateId,
  });
  const user = await refreshUser();
  dispatch({ type: action.REFRESH_USER, payload: user });
};
export const getCurrentLocationCoordsAction = (startingPoint,startingNeighborhood) => async (dispatch) => {
 

  dispatch({ type: action.SET_CURRENT_COORDS,payload:{startingPoint,startingNeighborhood}});

};
