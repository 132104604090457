import React, { useState,useEffect, Suspense, lazy } from "react";
//import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
//import houseData from "../../dummyData/data";

import {
  Loader,
 
} from "../../components/index";
import "./home.css";
import { loader } from "../../Assets";
import {  useSelector } from "react-redux";
//import { useNavigate } from "react-router-dom";
import { useTranslation} from 'react-i18next';

const PostCard = lazy(() => delayForDemo(import('../../components/postCard/PostCard')));

const GuestHouse = () => {
  const { t } = useTranslation();
  const { posts,isLoading } = useSelector((state) => state.posts);
  const [apartments, setApartments] = useState([]);
  const [filteredApartments, setFilteredApartments] = useState([])
  const [userAddress, setUserAddress] = useState(null);
  const { user } = useSelector((state) => state.user);
  const { displayName } = user || {};


  useEffect(() => {
    const newPosts =  posts.filter(
      ({post}) => post?.category?.match(t("for sale")))
      setFilteredApartments(newPosts)
      setApartments(newPosts)
  }, [posts,t])

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      const { latitude, longitude } = position.coords;
    
      // use the latitude and longitude to get the user's address
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyA7cBmAOfo6opESilIUNy8Vzvsc5mPdxIc`)
        .then(response => response.json())
        .then(data => {
          console.log(data)
          setUserAddress(data?.results[0]?.formatted_address);
        });
    });
  }, []);
console.log(userAddress)
console.log(apartments)

 

  // useEffect(() => {
  //     setApartments(posts)
    
  // }, [])
  


  if (isLoading) {
    return <Loader />;
  }

  return (
    <main className="home__screen" id="top">
      <div className="home__postsContainer text-secondary">
      <Suspense fallback={ 
            <img src={loader} alt="" className="loading__image"/>
         }>
        {filteredApartments?.map(({post, id}) => (
       
         
            <PostCard item={post} id={id} key={id} user={displayName} />
        
         
        ))}
        </Suspense>
      </div>
      {/* <div className="home__postsContainer text-secondary">
        {apartments?.map((item) => (
       
          <Suspense fallback={ 
            <img src={loader} alt="" className="loading__image"/>
         }>
            <PostCard item={item?.post} id={item?.id} key={item?.id} user={displayName} />
        </Suspense>
         
        ))}
      </div> */}
    </main>
  );
};

export default GuestHouse;

function delayForDemo(promise) {
  return new Promise(resolve => {
    setTimeout(resolve, 10000);
  }).then(() => promise);
}