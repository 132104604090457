import {h1,h1a,h1b, im1, im2, im3,im4, im5,} from "../Assets";


export const  landingImages2 =  [im1,im2,im4,im5]

export const  landingImages =  [h1,h1a,h1b,h1a,h1b,im3]
export const houseData = [
    {
        id:1,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Douala',
        neighborhood:'Bonaberi',
        price:'50, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        link:'/post/1',
        parking:'no',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
  
    {
        id:2,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Bamenda',
        neighborhood:'Up Station',
        price:'30, 000.00',
        currency:'FCFA',
        postedBy:'Abakwa Man',
        link:'/post/2',
        parking:'no',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6
},
    {
        id:3,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Apartment',
        city:'Bamenda',
        neighborhood:'Nobel Man',
        price:'60, 000.00',
        currency:'FCFA',
        postedBy:'Abakwa Man',
        link:'/post/3',
        bedrooms:'4',
        numberOfToilets:'3',
        dining:'yes',
        parking:'yes',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },{
        id:4,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Modern Single Room',
        city:'Buea',
        neighborhood:'Bondoma',
        price:'30, 000.00',
        currency:'FCFA',
        postedBy:'Mola Ndive',
        link:'/post/4',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:5,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio',
        city:'New York',
        neighborhood:'Queens',
        price:'500.00',
        currency:'USD',
        postedBy:'Hunter Man',
        link:'/post/5',
        parking:'yes',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:6,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Douala',
        neighborhood:'Bonaberi',
        price:'50, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        link:'/post/6',
        parking:'no',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:7,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Yaounde',
        neighborhood:'Damas',
        price:'50, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        link:'/post/7',
        parking:'yes',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:8,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Chambre Modern',
        city:'Yaounde',
        neighborhood:'Jouvence',
        price:'25, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        link:'/post/8',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:9,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Apartment',
        city:'Douala',
        neighborhood:'Lekki',
        price:'80, 000.00',
        currency:'NAIRA',
        postedBy:'Awala Boy',
        link:'/post/9',
        bedrooms:'3',
        numberOfToilets:'2',
        dining:'yes',
        parking:'no',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:10,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Malabo',
        neighborhood:'Rufisia',
        price:'70, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        link:'/post/10',
        parking:'yes',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    
    {
        id:11,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Apartment',
        city:'Bamenda',
        neighborhood:'New Bell',
        price:'60, 000.00',
        currency:'FCFA',
        postedBy:'Abakwa Man',
        link:'/post/11',
        bedrooms:'4',
        numberOfToilets:'2',
        dining:'yes',
        parking:'yes',
        number:'+237688992356',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:12,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Apartment Modern',
        city:'Douala',
        neighborhood:'Akwa Nord',
        price:'80, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        link:'/post/12',
        bedrooms:'3',
        numberOfToilets:'2',
        dining:'yes',
        parking:'yes',
        number:'+237688992356',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:13,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Douala',
        neighborhood:'Bonaberi',
        price:'50, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        link:'/post/1',
        parking:'no',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
  
    {
        id:14,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Bamenda',
        neighborhood:'Up Station',
        price:'30, 000.00',
        currency:'FCFA',
        postedBy:'Abakwa Man',
        link:'/post/2',
        parking:'no',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6
},
    {
        id:15,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Apartment',
        city:'Bamenda',
        neighborhood:'Nobel Man',
        price:'60, 000.00',
        currency:'FCFA',
        postedBy:'Abakwa Man',
        link:'/post/3',
        bedrooms:'4',
        numberOfToilets:'3',
        dining:'yes',
        parking:'yes',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },{
        id:16,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Modern Single Room',
        city:'Buea',
        neighborhood:'Bondoma',
        price:'30, 000.00',
        currency:'FCFA',
        postedBy:'Mola Ndive',
        link:'/post/4',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:17,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio',
        city:'New York',
        neighborhood:'Queens',
        price:'500.00',
        currency:'USD',
        postedBy:'Hunter Man',
        link:'/post/5',
        parking:'yes',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:18,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Douala',
        neighborhood:'Bonaberi',
        price:'50, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        link:'/post/6',
        parking:'no',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:19,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Yaounde',
        neighborhood:'Damas',
        price:'50, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        link:'/post/7',
        parking:'yes',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:20,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Chambre Modern',
        city:'Yaounde',
        neighborhood:'Jouvence',
        price:'25, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        link:'/post/8',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:21,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Apartment',
        city:'Douala',
        neighborhood:'Lekki',
        price:'80, 000.00',
        currency:'NAIRA',
        postedBy:'Awala Boy',
        link:'/post/9',
        bedrooms:'3',
        numberOfToilets:'2',
        dining:'yes',
        parking:'no',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:22,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Malabo',
        neighborhood:'Rufisia',
        price:'70, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        link:'/post/10',
        parking:'yes',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:23,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Douala',
        neighborhood:'Bonaberi',
        price:'50, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        link:'/post/1',
        parking:'no',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
  
    {
        id:24,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Bamenda',
        neighborhood:'Up Station',
        price:'30, 000.00',
        currency:'FCFA',
        postedBy:'Abakwa Man',
        link:'/post/2',
        parking:'no',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6
},
    {
        id:25,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Apartment',
        city:'Bamenda',
        neighborhood:'Nobel Man',
        price:'60, 000.00',
        currency:'FCFA',
        postedBy:'Abakwa Man',
        link:'/post/3',
        bedrooms:'4',
        numberOfToilets:'3',
        dining:'yes',
        parking:'yes',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },{
        id:26,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Modern Single Room',
        city:'Buea',
        neighborhood:'Bondoma',
        price:'30, 000.00',
        currency:'FCFA',
        postedBy:'Mola Ndive',
        link:'/post/4',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:27,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio',
        city:'New York',
        neighborhood:'Queens',
        price:'500.00',
        currency:'USD',
        postedBy:'Hunter Man',
        link:'/post/5',
        parking:'yes',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:28,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Douala',
        neighborhood:'Bonaberi',
        price:'50, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        link:'/post/6',
        parking:'no',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:29,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Yaounde',
        neighborhood:'Damas',
        price:'50, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        link:'/post/7',
        parking:'yes',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:30,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Chambre Modern',
        city:'Yaounde',
        neighborhood:'Jouvence',
        price:'25, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        link:'/post/8',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:31,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Apartment',
        city:'Douala',
        neighborhood:'Lekki',
        price:'80, 000.00',
        currency:'NAIRA',
        postedBy:'Awala Boy',
        link:'/post/9',
        bedrooms:'3',
        numberOfToilets:'2',
        dining:'yes',
        parking:'no',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:32,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Malabo',
        neighborhood:'Rufisia',
        price:'70, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        link:'/post/10',
        parking:'yes',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    {
        id:33,
        imageUrls:[h1,h1a,h1b,h1a,h1b],
        type:'Studio Modern',
        city:'Malabo',
        neighborhood:'Rufisia',
        price:'70, 000.00',
        currency:'FCFA',
        postedBy:'Hunter Man',
        link:'/post/10',
        parking:'yes',
        number:'+237698776543',
        email:'cmrhouse@gmail.com',
        timestamp:new Date(),
        numberAvailable:6

    },
    
    
    
]
