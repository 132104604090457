import React, { useRef, useEffect, useState } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import './map.css';

export default function Map({item,lon, lat}) {
    const { neighborhood } = item
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [zoom] = useState(14);
  // console.log(startingPoint)
  useEffect(() => {
    //if (map.current) return; // stops map from intializing more than once
// console.log(lat,lon)
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${process.env.REACT_APP_MAPTILER_API_KEY}`,
      center: [lon, lat],
      zoom: zoom
    });
    map.current.addControl(new maplibregl.NavigationControl(), 'top-right');
    const marker = new maplibregl.Marker({
      color: "#e90a0a",
      width:"50px",
      height: "50px",
  })
  .setLngLat([lon, lat])
  var popupOffsets = {
      top: [0, 0],
      bottom: [0, -50],
      left: [25, -35],
      right: [-25, -35]
  }

var popup = new maplibregl.Popup({ offset: popupOffsets })
popup.setHTML(neighborhood);
marker.setPopup(popup)
.addTo(map.current)
    // new maplibregl.Marker({color: "#FF0000"})
    //   .setLngLat([lon, lat])
      // .addTo(map.current);
  }, [lon, lat, zoom,neighborhood]);

  
   


  return (
    <div className="map-wrap">
      <div ref={mapContainer} className="map" />
      <div className="overlay">
    <button id="replay">Replay</button>
</div>
    </div>
  );
}










// import React,{ useEffect }from 'react'
// import { MapContainer, TileLayer, Marker, Popup,useMap } from 'react-leaflet';
// import { placeholder } from '../../Assets';
// import 'leaflet/dist/leaflet.css';
// import L from "leaflet";

// const icon = L.icon({
//     iconUrl: placeholder,
//     iconSize: [38, 38],
//   });
//   const defaultPosition = [51.505, -0.09];
// function ResetCenterView(props){
//     const {position} = props
//     const map = useMap();
//     useEffect(() => {
//         if(position){
//             map.setView(
//                 L.latLng(position[0],position[1]),
//                 map.getZoom(),
//                 {
//                     animate:true
//                 }
//             )
//         }
//     }, [position,map])
//    return null
// }
// const Map = ({item,position}) => {
//     const { neighborhood } = item


 
  
//   return (
//     <MapContainer
//       center={defaultPosition}
//       zoom={20}
//       scrollWheelZoom={true}
//       style={{
//         width: "100%",
//         height: "75vh",
//       }}>
//       <TileLayer
//         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//         url={`https://api.maptiler.com/maps/basic-v2/256/{z}/{x}/{y}.png?key=${process.env.REACT_APP_OPEN_STREET_MAP_KEY}`}
//       />
//       {position && (
//         <Marker position={position} icon={icon}>
//           <Popup>
//            {neighborhood}
//           </Popup>
//         </Marker>
//       )}
//       <ResetCenterView position={position}/>
//     </MapContainer>
//   )
// }

// export default Map