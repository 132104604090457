import React, { useState,useEffect,lazy } from "react";
import { useSelector } from "react-redux";
import "./forSale.css";

//import { useNavigate } from "react-router-dom";
import { useTranslation} from 'react-i18next';
import { NoPropertyFallback } from "../../components";

const PostCard = lazy(() => delayForDemo(import('../../components/postCard/PostCard')));

const ForSaleScreen = () => {
  const { forSale,} = useSelector((state) => state.posts)
  const { t } = useTranslation();
  //const  user  = JSON.parse(localStorage.getItem("user"))
  const [filtered, setFiltered] = useState([])
  const [value, setValue] = useState('')
 


  useEffect(() => {
  setFiltered(forSale)
  }, [forSale,t])
  

  useEffect(() => {
    const newPosts =  forSale?.filter(
      ({post}) => post?.type?.match(value.toLowerCase())) 
      // console.log(newPosts)
      // console.log(value,newPosts)
  
      if(newPosts?.length > 0){
        setFiltered(newPosts)
        
      }
      
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      const { latitude, longitude } = position.coords;
    
      // use the latitude and longitude to get the user's address
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyA7cBmAOfo6opESilIUNy8Vzvsc5mPdxIc`)
        .then(response => response.json())
        .then(data => {
          console.log(data)
          console.log(data)
        });
    });
  }, []);


const handleChange = (e) => {
  setValue(e.target.value)
  setFiltered([])
}

 

  if(!filtered){
    return (
      <NoPropertyFallback/>
    )
  }

  return (
    <main className="forSale__screen" id="top">
      <div className="forSale__filter">
            <select
              className="forSale__filter-select"
              labelid="type-select"
              id="typeSelect"
              label="apartmentType"
              name="type"
              onChange={handleChange}
              value={value}
            >
              <option>{t("filter").toUpperCase()}</option>
              <option value="house">{t("house").toUpperCase()}</option>
              <option value="plots">{t("plots").toUpperCase()}</option>
               <option value="bungalow">{t("bungalow").toUpperCase()}</option>
              <option value="simple apartment">{t("simple apartment").toUpperCase()}</option>
              <option value="flat">{t("flat").toUpperCase()}</option>
            </select>
            
          </div>
      <div className="forSale__postsContainer text-secondary">
  
        {filtered?.map((post) => (
            <PostCard item={post} id={post?.id} key={post?.id} />
        ))}
  
      </div>
      {/* <div className="forSale__postsContainer text-secondary">
        {apartments?.map((item) => (
       
          <Suspense fallback={ 
            <img src={loader} alt="" className="loading__image"/>
         }>
            <PostCard item={item?.post} id={item?.id} key={item?.id} user={displayName} />
        </Suspense>
         
        ))}
      </div> */}
    </main>
  );
};

export default ForSaleScreen;

function delayForDemo(promise) {
  return new Promise(resolve => {
    setTimeout(resolve, 10000);
  }).then(() => promise);
}