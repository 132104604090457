
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
const ProtectedRoute = ({
  redirectPath = "/",
  //redirectPath1 = "/subscribe",
  
}) => {
  const { user } = useSelector((state) => state.user)
  const {  refreshedUser } = useSelector((state) => state.posts)
  // const { id,isSubscribed,displayName } = user?.user || {};
  const { displayName  } = user || {};
  const { isSubscribed  } = refreshedUser || {};

//console.log(isSubscribed)



  
 
  if (!displayName) {
    return <Navigate to={redirectPath} />;
  }else if ( displayName && isSubscribed === false) {
    return <Navigate to={redirectPath} />;
  }
  return <Outlet />;
};
export default ProtectedRoute;
