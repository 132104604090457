export const GET_SAVED_BY = 'GET_SAVED_BY';
export const REMOVE_SAVED_BY = 'REMOVE_SAVED_BY';

export const CREATE_NEW_POST = 'CREATE_NEW_POST';
export const DELETE_POST = 'DELETE_POST';
export const GET_ALL_POSTS = 'GET_ALL_POSTS';
export const GET_FAVORITES = 'GET_FAVORITES';
export const GET_ALL_RENTS_POSTS = 'GET_ALL_RENTS_POSTS';
export const GET_ALL_SALE_POSTS = 'GET_ALL_SALE_POSTS';
export const REMOVE_FROM_FAVORITES = 'REMOVE_FROM_FAVORITES'
export const SAVED_TO_FAVORITES = 'SAVED_TO_FAVORITES'
export const START_LOADING = 'START_LOADING'
export const START_LOADING_SEARCH = 'START_LOADING_SEARCH'
export const END_LOADING ='END_LOADING'
export const START_AUTH_LOADING = 'START_AUTH_LOADING'
export const END_AUTH_LOADING ='END_AUTH_LOADING'
export const LOG_IN_USER ='LOG_IN_USER'
export const LOG_OUT_USER ='LOG_OUT_USER'
export const SET_LOGIN_MESSAGE ='SET_LOGIN_MESSAGE'
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM'
export const SUBSCRIBE_USER = 'SUBSCRIBE_USER'
export const UNSUBSCRIBE_USER = 'UNSUBSCRIBE_USER'
export const GET_SUBSCRIPTION_EXPIRY_DATE = 'GET_SUBSCRIPTION_EXPIRY_DATE'
export const REFRESH_USER = 'REFRESH_USER'
export const GET_SUBSCRIPTION_EXPIRY_DATE_ID = 'GET_SUBSCRIPTION_EXPIRY_DATE_ID'
export const SET_CURRENT_COORDS = 'SET_CURRENT_COORDS'
