import i18n from 'i18next';
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from 'react-i18next';
import detector from "i18next-browser-languagedetector";


//const getCurrentHost = process.env.REACT_APP_MODE === 'development' ? 'http://localhost:3000' : 'https://housing-88dc5.web.app'

i18n
.use(detector)
    .use(i18nBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        lng: 'en',
        interpolation: {
            escapeValue: false,
            load: "languageOnly",
            debug: true,
            react: {
                transSupportBasicHtmlNodes: true,
                transKeepBasicHtmlNodesFor: ["br", "strong", "i", "sub", "sup", "li"],
            }
        },
        backend: {
             loadPath: () => {
                 const host = window.location.host;
                return (host === 'housing-88dc5.web.app' ? '/i18n/{{lng}}.json':'i18n/{{lng}}.json');
               },
        }
    });

export default i18n;





























// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

// i18n
//   // detect user language
//   // learn more: https://github.com/i18next/i18next-browser-languageDetector
//   .use(LanguageDetector)
//   // pass the i18n instance to react-i18next.
//   .use(initReactI18next)
//   // init i18next
//   // for all options read: https://www.i18next.com/overview/configuration-options
//   .init({
//     debug: true,
//     fallbackLng: 'en',
//     interpolation: {
//       escapeValue: false, // not needed for react as it escapes by default
//     },
//     resources: {
//         en: {
//           translation: {
//             description: {
//               register: 'Register',
//               login: 'Login'
//             }
//           }
//         },
//         fr: {
//           translation: {
//             description: {
//               registerButton: "s'inscrire",
//               loginButton: 'Connexion'
//             }
//           }
//         }
//       }
//   });

// export default i18n;

