import './styles.css'
import { useTranslation } from 'react-i18next';
const NoPropertyFallback = () => {
    const { t } = useTranslation();
 (
    <main className="home__screen-fallback" id="top">
    <div className="home__postsContainer text-secondary">
      <h2 className="text-danger">{t("fallback")}</h2>
      </div>
    </main>
  )}


export default NoPropertyFallback