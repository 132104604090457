import React from 'react';
import { useTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';
import './footer.css'
import { logo } from '../../Assets';


const Footer = () => {
   const { t } = useTranslation();

  return (
   <footer>
   <div className="footer__pages">
   <div className="footer__links">
       {/* <Link to='/' className="footer_link"> {t("home")}</Link> */}
       {/* <Link to='/contact' className="footer_link">Contact</Link>
       <Link to='support' className="footer_link">Support</Link> */}
         <Link to="/">
         <div className="footer__logo-container">
          <img src={logo} alt="logo" />
          </div>
      </Link>
       </div>
       <div className="footer__container">
       <div className="footer__contacts">
       <span className="footer__contactsTitle"> {t("contact")}</span>
        <div className="footer__contact">
        {/* <BsWhatsapp size={20} color='#06e651'/> */}
        <ReactWhatsapp number={'+237677972126'} message={`Hello sir/madame, good day!! you've reached   <span className="website__nameFooter">FindMeHouse</span> Team`}>
        <span className="whatsappFooter__button">
        {t("message")}
         </span>
        </ReactWhatsapp>
        <span className="footer__contactOption">23bigcompound@gmail.com</span>
        </div>
       </div>
       </div>
   </div>
       <div className="footer__copyright">
          <span className="website__nameFooter">HOMESCRAPER &copy; 2023</span> 
       </div>
   </footer>
  )
}

export default Footer