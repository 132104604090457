import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import { applyMiddleware, compose, createStore} from 'redux'
import thunk from 'redux-thunk'
import reportWebVitals from './reportWebVitals';
import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/material.css'
import 'react-toastify/dist/ReactToastify.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './i18n';
import IPInfo from 'ip-info-react';
import reducer from './redux/index'
const store = createStore(reducer, compose(applyMiddleware(thunk)));


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

   <BrowserRouter>
    {/* <React.StrictMode> */}
  <Provider store={store}>
   <IPInfo>
   <App />
   </IPInfo>
   </Provider>
   {/* </React.StrictMode> */}
   </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
