import React from "react";
import { Link } from "react-router-dom";
import "./postCard.css";
import { AiFillDelete } from "react-icons/ai";
import { Timestamp } from "firebase/firestore";
import { IoLocationOutline } from "react-icons/io5";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ToastContainer, } from "react-toastify";
import { removeFromFavoritesAction } from "../../redux/actions";



const FavoritePostCard = (item) => {
 const { user } = useSelector((state) => state?.user)
  const dispatch = useDispatch()
  const { id } = user || {};
  const { t } = useTranslation();
  const {
    type,
    city,
    neighborhood,
    price,
    currency,
    category,
    bedrooms,
    imageUrls,
    timestamp,

  } = item?.item?.item

  // console.log(item?.item?.item)
  // console.log(item?.item?.id)
  const fieldVal = timestamp;
  const timeStamp = new Timestamp(fieldVal?.seconds, fieldVal?.nanoseconds);
  const date = timeStamp.toDate();
  const finalDate = moment(date).startOf("second").fromNow();
 

const removeFavorite = () => {
  dispatch(removeFromFavoritesAction(id,item?.item?.id))
}
  

  return (
    <div className="post__container m-2">
      <div className="postCard__image">
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        theme="colored"
      />
        <div className="postCard__header">
        <h5 className="city__container-head">
        <IoLocationOutline size={13} color="#FFFFFF"/>
        <span className="city">{city}</span>
        </h5>
        {/* <h5 className="postedBy">{type}</h5>  */}
            <h5 className="bedrooms">
              {t("no of bedrooms")}: {bedrooms}{" "}
            </h5>
        </div>
        <img src={imageUrls[0]} alt="post card" loading="lazy" />
        
      </div>
      <div className="type">
        <span className="subtitles"></span> {type.toUpperCase()}
      </div>
      <div className="postCard__body">
        <div className="card__bodyBox">
          <div className="card__subtitlesContainer">
            <h5 className="post__price">
               {neighborhood}
            </h5>
            <h5 className="post__price">{price}{" "}
            {currency} {
              category === "for rent" && type !== "guest house" ? "/month" : category === "for rent" && type === "guest house" ? "/night" : ''
            }
          </h5>

            <h5 className="subtitle">Posted {finalDate}</h5>
          </div>
          <div className="avatar__button">
            <Link to={`/favorite/${item?.item?.id}`} 
            className="post__more" 
            state={item?.item?.item}>
              {t("more")}
            </Link>{" "}
          </div>
        </div>
        <div className="actions__container d-flex justify-content-between">
            <AiFillDelete
              className="icon"
              color="#000"
              onClick={removeFavorite}
            />
            
          </div>
      </div>
    </div>
  );
};

export default FavoritePostCard;
