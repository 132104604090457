import React, { useEffect } from 'react'
// import { MdOutlineSort } from 'react-icons/md';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
import './search.css';
import { useTranslation } from 'react-i18next';
import { IoSearch } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom';
import { today } from '../../utils/utils';
import { useSelector } from 'react-redux';




const Search = ({searchProperty,searchTerm, setSearchTerm,setSearchResponse}) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { subscriptionExpiryDate } = useSelector((state) => state.posts)


 useEffect(() => {
  if(!searchTerm){
    setSearchResponse("")
   
  }
 }, [navigate,setSearchResponse,searchTerm])
 
  
  const handleChange = (e) => {
    if(!subscriptionExpiryDate || today > subscriptionExpiryDate){
      navigate("/subscribe")
        }
   setSearchTerm(e.target.value)  
  }
    //  const handleSearch = (e) => {
    //   e.preventDefault();
    //   navigate('/')
    //   const searchValue = e.target.value;
    // setSearchTerm(searchValue)
    // dispatch(searchAction(posts,searchTerm))
    // setSearchTerm("")
      
    //  }
  return (
    <div className="header__searchBox">
     <div className="header__search1">
     <form  onSubmit={searchProperty} className='input'>
      <IoSearch className='location' size={25} color='#fff'/>
      <input
      type='text'
      className='search__input'
       margin="normal"
        placeholder={t('search')}
        value={searchTerm}
        onChange={handleChange}
      />
      </form>
     </div>   
    </div>
    
  )
}

export default Search