import React from 'react'
import AgentProfile from './AgentProfile'


const Profile = () => {
  return (
<AgentProfile/>
  )
}

export default Profile