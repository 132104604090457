import React, { useState,useEffect } from "react";
import "./register.css";
import useValidator from "../../../utils/CustomValidator";
import { Link, useNavigate } from "react-router-dom";
import { AiFillEyeInvisible,AiFillEye } from 'react-icons/ai';
// import { messageActions } from "../../redux/authSlice/messageSlice";
// import { registerUser } from "../../redux/authSlice/authSlice";
// import { useSelector, useDispatch } from 'react-redux';
import { loader } from '../../../Assets/index';
import { useTranslation} from 'react-i18next';
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { RegisterAction } from "../../../redux/actions";


const Register = () => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const { message, isLoading } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    username:"",
    email: "",
    password: "",
    firstName: '',
    lastName:'',
    country:'',
    city:'',
    phoneNumber:phoneNumber,
    role:"",
    isSubscribed:false,
    isTrialDone:false
  });
  const [showPassword, setShowPassword] = useState(false);
   const [validator, showValidationMessage] = useValidator();
 const dispatch = useDispatch();
  const navigate = useNavigate()


  // useEffect(() => {
  //   dispatch(messageActions.clearMessage())
  // }, [dispatch])

  useEffect(() => {
    const result = document.getElementById('phone__input').getElementsByClassName('special-label');
    result[0].style.display = 'none';
  }, []);

  const handlePhoneInputChange = (phone) => {
    setPhoneNumber(phone);
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };
  const handleShowPassword = () =>
  setShowPassword((prevShowPassword) => !prevShowPassword)

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
     dispatch(RegisterAction(formData,navigate))
    } else {
      showValidationMessage(true);
      
    }
    
  };


  return (
    <div className="register__container">
      <form className="register__form" onSubmit={handleSubmit}>
        <h3 className="register__header">{t("register message")} <span className="website__name">HOMESCRAPER</span></h3>
        <div className="register__input">
          <input
            type="text"
            placeholder={t("username")}
            name='username'
            value={formData.username}
            onChange={handleInputChange}
            className=""
            required
          />
          <label className="register__error text-danger">
            {validator.message("username", formData.username, "required|username", {
              messages: {
                required: t("validate"),
              },
            })}
          </label>
        </div>
        <div className="register__input">
          <input
            type="text"
            placeholder={t("first name")}
            name='firstName'
            value={formData.firstName}
            onChange={handleInputChange}
            className=""
            required
          />
          <label className="register__error text-danger">
            {validator.message("firstName", formData.firstName, "required|firstName", {
              messages: {
                required: t("validate"),
              },
            })}
          </label>
        </div>
        <div className="register__input">
          <input
            type="text"
            placeholder={t("last name")}
            name='lastName'
            value={formData.lastName}
            onChange={handleInputChange}
            className=""
            required
          />
          <label className="register__error text-danger">
            {validator.message("lastName", formData.lastName, "required|lastName", {
              messages: {
                required: t("validate"),
              },
            })}
          </label>
        </div>
        <div className="register__input">
            <select
              className="register__select"
              labelid="role-select"
              id="roleSelect"
              label="role"
              name="role"
              onChange={handleInputChange}
              value={formData.role}
            >
              <option>{t("role")}</option>
              <option value="client">{t("client")}</option>
              <option value="agent">{t("agent")}</option>
            </select>
            <label className="error text-danger">
              {validator.message(
                "role",
                formData.role,
                "required|role",
                {
                  messages: {
                    required: t("required"),
                  },
                }
              )}
            </label>
          </div>
        <div className="register__input">
          <input
            type="text"
            placeholder={t("country")}
            name='country'
            value={formData.country}
            onChange={handleInputChange}
            className=""
            required
          />
          <label className="register__error text-danger">
            {validator.message("country", formData.country, "required|country", {
              messages: {
                required: t("validate"),
              },
            })}
          </label>
        </div>
        <div className="register__input">
          <input
            type="text"
            placeholder={t("city")}
            name='city'
            value={formData.city}
            onChange={handleInputChange}
            className=""
            required
          />
          <label className="register__error text-danger">
            {validator.message("city", formData.city, "required|city", {
              messages: {
                required: t("validate"),
              },
            })}
          </label>
        </div>
        <div className="register__input">
          <input
            type="email"
            placeholder={t("email")}
            name='email'
            value={formData.email}
            onChange={handleInputChange}
            className=""
            required
          />
          <label className="register__error text-danger">
            {validator.message("email", formData.email, "required|email", {
              messages: {
                required: t("validate"),
              },
            })}
          </label>
        </div>
        <div className="register__input password">
        <input
          type={showPassword ? 'text' : 'password'} 
          placeholder={t("password")}
          name='password'
          value={formData.password}
          onChange={handleInputChange}
          className=""
          required
        />
         <div className="register__show-password">
         { showPassword ? <AiFillEyeInvisible className="register__show-password" onClick={handleShowPassword}/> :
        <AiFillEye className="register__show-password" onClick={handleShowPassword}/>}
         </div>
          <label className="register__error text-danger"></label>
          <label className="register__error text-danger">
            {validator.message("password", formData.password, "required|password", {
              messages: {
                required: t("validate"),
              },
            })}
          </label>
        </div>
        {/* <div className="register__input">
        <input
          type={showPassword ? 'text' : 'password'} 
          placeholder="Confirm Password"
          name='confirmPassword'
          value={formData.confirmPassword}
          onChange={handleInputChange}
          className=""
          required
        />
         { showPassword ? <AiFillEyeInvisible className="register__show-password" onClick={handleShowPassword}/> :
        <AiFillEye className="register__show-password" onClick={handleShowPassword}/>}
          <label className="register__error text-danger">
            {validator.message("confirmPassword", formData.confirmPassword, "required|confirmPassword", {
              messages: {
                required: t("validate"),
              },
            })}
          </label>
        </div> */}
        <div className="register__input" id="phone__input">
            <PhoneInput
              type="text"
              country={"cmr"}
              enableAreaCodes={true}
              // onlyCountries={['us','de','ro']}
              // areaCodes={{us: ['999']}}
              inputProps={{
                name: "phoneNumber",
                country: "cm",
                required: true,
                autoFocus: true,
              }}
              // containerStyle={{
              //   Padding: "5px",
              // }}
              inputStyle={{
                width: "100%",
                height: "42px",
                borderRadius: "10px",
                outline: "none",
                margin:"10px 0",
                backgroundColor: "#fff",
                border: "none",
              }}
              regions={["north-america", "africa", "europe"]}
              value={phoneNumber}
              onChange={handlePhoneInputChange}
              // onChange={props.inputChange}
              // value={props.data.phonNumber}
              isValid={(value, country) => {
                if (value.match(/12345/)) {
                  return "Invalid value: " + value + ", " + country.name;
                } else if (value.match(/1234/)) {
                  return false;
                } else {
                  return true;
                }
              }}
            />
            <label className="error text-danger">
              {validator.message(
                "phoneNumber",
                phoneNumber,
                "required|phoneNumber",
                {
                  messages: {
                    required: t("required"),
                  },
                }
              )}
            </label>
          </div>
        <div className="register__support">
          <Link to='/login' className="register__supportLink">{t("created account")}</Link>
        </div>
        {isLoading && (
                  <img src={loader} alt="" className="loading__image"/>
                )}
        <button type="submit" className="register__submit">
       
        {t("register")}</button>
        {
      message && (
        <div className="form-group">
          <div className="alert alert-danger" role='alert'>
            {message}
          </div>
        </div>
      )
    }
      </form>
    </div>
  );
};

export default Register;
