import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./favorite.css";
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import ReactWhatsapp from "react-whatsapp";
import { loader } from "../../../../Assets";
import { useTranslation } from 'react-i18next';
import { RiWhatsappLine } from "react-icons/ri";
import { Carousel } from 'react-responsive-carousel';

const Favorite = () => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const [number, setNumber] = useState("237677983166");
    const {
      type,
      country,
      city,
      neighborhood,
      price,
      category,
      currency,
      numberOfToilets,
      numberAvailable,
      bedrooms,
      dining,
      parking,
      phoneNumber,
      postedBy,
      imageUrls,
      timestamp,
  
    } = state;
    
    const fieldVal = timestamp;
    const timeStamp = new Timestamp(fieldVal?.seconds, fieldVal?.nanoseconds);
    const date = timeStamp.toDate();
    const finalDate = moment(date).startOf("second").fromNow();
  
    useEffect(() => {
      if (phoneNumber !== null) {
        setNumber(phoneNumber);
      }
    }, [phoneNumber]);
  return (
    <div className="favorite__details">
      <div className="details__heading">
        <h2>{type.toUpperCase()} {t("details")}</h2>
      </div>
      <div className="favoriteDetails__container d-flex justify-content-center align-items-center gap-2 flex-wrap">

        <div className="favorite__fullInfo">
          <h5 className="favoriteDetails__emphasis">
            {t("quarter").toUpperCase()}{" "}
            {neighborhood.toUpperCase()}
          </h5>
          {type === "Apartment" && (
            <>
              <h5 className="favoriteDetails__emphasis">
                {bedrooms}{" "}{t("no of bedrooms").toUpperCase()}
              </h5>
              <h5 className="favoriteDetails__emphasis">
                {numberOfToilets}{" "}{t("no of toilets").toUpperCase()}
              </h5>
              <h5 className="favoriteDetails__subtitle">
                {dining.toUpperCase()}
              </h5>
            </>
          )}
          <h5 className="favoriteDetails__subtitle">
            {parking.toUpperCase()}{" "}
          </h5>
          {
            category === "For Rent" && (
              <>
                {/* <h5 className="favoriteDetails__subtitle">
             {quality}
          </h5> */}
                <h5 className="favoriteDetails__subtitle">
                  {numberAvailable}{" "}{type.toUpperCase()} {" "} {t("no available")}
                </h5>
              </>
            )
          }
          <h5 className="favoriteDetails__emphasis">{price}{" "}
            {currency}
          </h5>
          <h5 className="favoriteDetails__emphasis">
            {phoneNumber}{" "}
          </h5>
          <h5 className="favoriteDetails__subtitle">
            <ReactWhatsapp
              number={number}
              message={`Hello sir/madame, good day!! you've reached ${postedBy}`}
            >
             <div className="whatsappFavorite__button">
                <RiWhatsappLine
                color="#06e651"
                size={15}
              />{" "}<h5 className="subtitle"> click to message {postedBy}</h5>
                </div>
            </ReactWhatsapp>{" "}
          </h5>
          <h5 className="favoriteDetails__subtitle">
            {country}{" "}
          </h5>
          <h5 className="favoriteDetails__subtitle">
            {city.toUpperCase()}{" "}
          </h5>
          <h5 className="favoriteDetails__subtitle">
            {t("posted by")?.toUpperCase()} {" "} {postedBy?.toUpperCase()}{" "}
          </h5>
          <h5 className="favoriteDetails__subtitle">
            {finalDate}{" "}
          </h5>
        </div>
        <div className="carousel-container">
          {imageUrls?.length === 0 ? (
            <img src={loader} alt="" className="loading__image" />
          ) : (
            <Carousel
              className='carousel'
              axis='horizontal'
              dynamicHeight={true}
              showArrows={true}
              showIndicators={false}
              showThumbs={false}
              autoPlay={false}
              infiniteLoop={false}
              showStatus={false}
            >
              {
                imageUrls.map((image) => (
                  <div className="imageSlider__images">
                    <img src={image} alt='details slider' loading="lazy" className='' key={image.id} />
                  </div>
                ))
              }
            </Carousel>
          )}
        </div>
      </div>
    </div>
  )
}

export default Favorite