import React, { useState, useEffect } from "react";
import { useLocation,Link } from "react-router-dom";
import "./postDetails.css";
import ReactWhatsapp from "react-whatsapp";
import { loader } from "../../Assets";
import { useTranslation } from 'react-i18next';
import { RiWhatsappLine } from "react-icons/ri";
import { Carousel } from 'react-responsive-carousel';
import { dateConverter, getCoordinates } from "../../utils/utils";
import { useSelector } from 'react-redux'
import {
  //VideoPlayer
  Map
  //MapContainer
} from "../../components";


const PostDetails = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { refreshedUser } = useSelector((state) => state.posts)
  const { isSubscribed } = refreshedUser || {}
  const [number, setNumber] = useState("237677983166");
  const [mapLongitude, setMapLongitude] = useState(-0.09);
  const [mapLatitude, setMapLatitude] = useState(51.505);
  const [position, setPosition] = useState([+mapLatitude, +mapLongitude]);
  const {
    
    country,
    city,
    neighborhood,
    price,
    type,
    category,
    currency,
    numberOfToilets,
    numberAvailable,
    bedrooms,
    dining,
    parking,
    phoneNumber,
    postedBy,
    imageUrls,
    timestamp,
    fenced,
    // videoUrl

  } = state?.post || {};
  const finalDate = dateConverter(timestamp)



  useEffect(() => {
    const getData = async () => {
      const data = await getCoordinates(neighborhood, city)
    //  console.log(data)
      setMapLatitude(parseFloat(data?.lat))
      setMapLongitude(parseFloat(data?.lon))
    }
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPosition((prevState) => [+mapLatitude, +mapLongitude]);
  }, [mapLatitude, mapLongitude]);

  useEffect(() => {
    if (phoneNumber !== null) {
      setNumber(phoneNumber);
    }
  }, [phoneNumber]);


  return (
    <div className="post__details">
      <div className="details__heading">
        <h2>{type} {t("details")}</h2>
      </div>
      <div className="postDetails__container d-flex justify-content-center align-items-center gap-2 flex-wrap">

        <div className="post__fullInfo">
          <h5 className="postDetails__emphasis">
            {t("quarter")}{" "}
            {neighborhood}
          </h5>
          <h5 className="postDetails__emphasis">
            {t("no of bedrooms")}{" "}{bedrooms}
          </h5>
          <h5 className="postDetails__emphasis">
            {t("no of toilets")}{" "}{numberOfToilets}
          </h5>
          <h5 className="postDetails__subtitle">
            {dining}
          </h5>
          <h5 className="postDetails__subtitle">
            {t(fenced)}
          </h5>
          <h5 className="postDetails__subtitle">
            {parking}
          </h5>
          {
            category === "for rent" && (
              <>
                <h5 className="postDetails__subtitle">
                  {numberAvailable}{" "} {t("available")}
                </h5>
              </>
            )
          }
          <h5 className="postDetails__emphasis">{price}{" "}
            {currency} {
              category === "for rent" && type !== "Guest House" ? "/month" : category === "for rent" && type === "Guest House" ? "/night" : ''
            }
          </h5>
          {
            isSubscribed === true ? (
              <>
                <h5 className="postDetails__emphasis">
                  {phoneNumber}{" "}
                </h5>
                <h5 className="postDetails__subtitle">
                  <ReactWhatsapp
                    number={number}
                    message={`Hello sir/madame, good day!! you've reached ${postedBy}`}
                  >
                    <div className="whatsappPost__button">
                      <RiWhatsappLine
                        color="#06e651"
                        size={15}
                      />{" "}<h5 className="subtitle"> click to message {postedBy}</h5>
                    </div>
                  </ReactWhatsapp>{" "}
                </h5>
              </>
            ) : (
              <Link to="/subscribe" className="postDetails__subtitle2">
              {t("see contact")}
              </Link>
            )
          }

          <h5 className="postDetails__subtitle">
            {country}{" "}
          </h5>
          <h5 className="postDetails__subtitle">
            {city}{" "}
          </h5>
          <h5 className="postDetails__subtitle">
            {t("posted by")} {" "} {postedBy}{" "}
          </h5>
          <h5 className="postDetails__subtitle">
            {finalDate}{" "}
          </h5>
        </div>
        <div className="carousel-container">
          {imageUrls?.length === 0 ? (
            <img src={loader} alt="" className="loading__image" key={imageUrls[0]} />
          ) : (
            <Carousel
              className='carousel'
              axis='horizontal'
              dynamicHeight={true}
              showArrows={true}
              showIndicators={false}
              showThumbs={false}
              autoPlay={false}
              infiniteLoop={false}
              showStatus={false}
            >
              {
                imageUrls?.map((image, index) => (
                  <div className="imageSlider__images" key={index}>
                    <img src={image} alt='details slider' loading="lazy" />
                  </div>
                ))
              }
            </Carousel>
          )}
        </div>
      </div>
      {/* <div className="post__details-video">
      {
        videoUrl && (
          <VideoPlayer videoSrc={videoUrl}/>
        )
       } 
      </div>  */}
      {
            isSubscribed === true ? (
      <div className="post__map">
        <Map item={neighborhood} position={position} lon={mapLongitude} lat={mapLatitude} destination={[mapLongitude,mapLatitude]}/>
      </div>
        ) : (
        <Link to="/subscribe" className="postDetails__subtitle2">
        {t("see map")}
        </Link>
      )
    } 
    </div>
  );
};

export default PostDetails;
