import React,{Suspense,useEffect} from 'react'
import { useSelector,useDispatch } from "react-redux";
import { loader } from '../../../../Assets';
import { FavoritePostCard } from '../../../../components';
import { getFavoritesAction } from '../../../../redux/actions';
import './favorites.css'




const Favorites = () => {
  const {  favorites,isLoading} = useSelector((state) => state.posts)
  const { user } = useSelector((state) => state.user)
  const { id } = user || {};
  const dispatch = useDispatch()


  useEffect(() => {
   dispatch(getFavoritesAction(id))
   console.log(favorites)
   // eslint-disable-next-line
  }, []);
  return (
    <div className='favorites__screen'>
      <div className="favorites__header">
        <h3 className="favorites__header-text">SAVED PROPERTIES</h3>
      </div>
       <div className="favorites__postsContainer text-secondary">
     { !isLoading && (
           <Suspense fallback={
            <img src={loader} alt="" className="loading__image" />
          }/>
           ) }
           <div className="favorites__fallback">
            {
              favorites?.length === 0 && (
                <p>No properties saved to Favorites</p>
              )
            }
           </div>
        <Suspense fallback={
          <img src={loader} alt="" className="loading__image" />
        }>

          {favorites?.map((post) => (
            <FavoritePostCard item={post} key={post?.id} />
      ))}
        </Suspense>

      </div>
    </div>
  )
}

export default Favorites
// const toggleFavorites = async (e) => {
//   e.preventDefault()
//      if(!user){
//      toast.info( t("save"), {
//         position: "bottom-right",
//         autoClose: 10000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "dark",
//       });
//      }else if(user && savedBy.indexOf(currentUserId) > -1){
//     dispatch(removeFromFavoritesAction(currentUserId,id))
//     //dispatch(getUsersWhoSavedToFavoritesAction(id,currentUserId))
//     }else if(favorites?.indexOf(id) === -1 && savedBy?.indexOf(currentUserId) === -1){
//       dispatch(saveToFavoritesAction(item?.post,id,currentUserId))
//       //dispatch(getUsersWhoSavedToFavoritesAction(id,currentUserId))
//       toast.info( t("saved"), {
//        position: "top-right",
//        autoClose: 10000,
//        hideProgressBar: true,
//        closeOnClick: true,
//        pauseOnHover: true,
//        draggable: true,
//        progress: undefined,
//        theme: "dark",
//      });
//     }else{}
 
     
//     }