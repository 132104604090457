import React from 'react';

import './verification.css';
import { useTranslation} from 'react-i18next';

const VerifyEmail = () => {
  const { t } = useTranslation();

  return (
   <div className="verify__container">
     <div className="header">
     </div>
     <div className="body">
      <p className='text'>{t("verify")}</p>
      <span className="website__name">HOMESCRAPER Team</span>
     </div>
    
   </div>
  )
}

export default VerifyEmail
