import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./dashboard.css";
import { signOut } from "firebase/auth";
import { auth } from "../../../firebaseConfig";
import { useTranslation} from 'react-i18next';
import { useSelector } from "react-redux";

const Dashboard = () => {
  const { t } = useTranslation();
  const [postCount, setPostCount] = useState(0);
  const posts = JSON.parse(localStorage.getItem("posts"))
  const { user } = useSelector((state) => state?.user)
  const { displayName, id } = user || {};

  useEffect(() => {
   const newPosts = posts.filter(({ post }) => (
      post?.userId?.match(id)
    ));
    // console.log(newPosts)
    setPostCount(newPosts?.length)
  }, [posts,id]);

  

  // console.log(id)
  // console.log(user)
  // console.log(postCount)

  /**===============LOGOUT=================== */
  const logOut = () => {
    signOut(auth)
      .then(() => {})
      .catch((error) => {});
  };

  return (
    <div className="dashboard__container">
      <div className="dashboard__header">
        <button onClick={logOut} className="logout__button btn btn-secondary">
        {t("logout")} {displayName}
        </button>
        <Link to="/create-post" className="createPost__link btn btn-secondary">
        {t("create for rent")}
        </Link>
        {/* <Link to="/create-for-sale" className="createPost__link btn btn-secondary">
        {t("create for sale")}
        </Link> */}
       {
        user?.role === "client" && (
          <Link to={`/favorites/${id}`} className="createPost__link btn btn-secondary">
          {t("favorites")}
          </Link>
        )
       }
        {/* <img
          alt="avatar"
          src={photoURL ? photoURL : profile}
          className="profile__avatar"
        /> */}
      </div>
      <div className="dashboard__dataContainer">
        <div className="posts__count">
          <div className="post__countContainer">
            <span className="post__countTitles"> {t("no of posts")}:</span>{" "}
            {postCount}
          </div>
          <div className="post__countContainer">
            <span className="post__countTitles"> {t("views")}:</span> 0
          </div>
          <div className="post__countContainer">
            <span className="post__countTitles"> {t("deleted")}:</span> 0
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
